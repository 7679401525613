<template>
  <main class="main">
    <!-- main section -->
    <section class="main-section">
      <div class="main-content">
        <div class="col">
          <img
            class="logo"
            src="../assets/kundu-kanyon-logo-2.png"
            alt="kundu kanyon logo"
          />
        </div>
        <div class="col">
          <h3>DESIGNED FOR HIGH RENTAL INCOME</h3>
        </div>
        <div class="col"><h4>RESIDENCES & SUITES</h4></div>
        <div class="col mt-2">
          <router-link to="/project" :title="$t('Explore')">{{
            $t("Explore")
          }}</router-link>
        </div>
      </div>
    </section>
    <!-- end main section -->

    <!-- main bottom nav -->
    <section class="main-bottom-nav">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <div class="nav-item">
              <span
                >{{ $t("CityCenter") + ":" }} {{ $t("CityCenterValue") }}</span
              >
            </div>
          </div>
          <div class="col">
            <div class="nav-item">
              <span>{{ $t("Airport") + ":" }} {{ $t("AirportValue") }}</span>
            </div>
          </div>
          <div class="col">
            <div class="nav-item">
              <span>{{ $t("Beach") + ":" }} {{ $t("BeachValue") }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end main bottom nav -->

    <!-- description -->
    <section class="description container-fluid text-center mx-0 px-0">
      <div class="container-fluid my-5 px-0">
        <div class="desc">
          <p class="px-2">
            {{ $t("GeneralDesc") }}
          </p>
          <Suites />
        </div>
      </div>
    </section>
    <!-- end description -->

    <!-- tabs start -->
    <section class="tab-side container-fluid">
      <div class="container">
        <b-tabs content-class="mt-5">
          <!-- 1+1 tab -->
          <b-tab title="1+1" active>
            <!-- slider -->
            <VueperSlides
              arrows-outside
              fractions
              progress
              :bullets="false"
              :gap="5"
              autoplay
              :slide-ratio="1 / 4"
              class="no-shadow vueper-slide"
              :visible-slides="3"
              :dragging-distance="70"
              :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
            >
              <VueperSlide
                class="slide-item"
                v-for="(slide, i) in slides1_1"
                :key="i"
                :image="slide.image"
              >
              </VueperSlide>
            </VueperSlides>

            <!-- description -->
            <div class="row mt-5">
              <div class="col-lg-6 col-md-12">
                <img
                  class="img-thumbnail tab-desc-img"
                  src="@/assets/1+1/kunduCanyon-Salon.jpg"
                  alt="kundu kanyon"
                  loading="lazy"
                  style="width: 100%; object-fit: cover; height: 380px"
                />
              </div>
              <div class="col-lg-6 col-md-12 desc-tab">
                <h1>{{ $t("FlatFeatures") }}</h1>
                <p>{{ $t("1+1FlatFeature1") }}</p>
                <p>
                  {{ $t("1+1FlatFeature2") }}
                </p>
                <div>
                  <router-link to="/project">{{ $t("AllFlats") }}</router-link>
                </div>
              </div>
            </div>

            <!-- card -->
            <div class="row mt-5 card-section">
              <div class="col-lg-4">
                <div class="card">
                  <!-- <div class="card-header">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="card-body">
                    <h4>Card Title</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Reprehenderit odit, dolorum quaerat quo aliquid modi
                      veritatis voluptatibus fugiat vero dolor illo eum
                      provident nisi quia totam. Numquam exercitationem et
                      ullam.
                    </p>
                  </div> -->
                  <img
                    src="@/assets/1+1/1+1ic-gorseller.jpg"
                    alt="1+1 image"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card">
                  <!-- <div class="card-header">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="card-body">
                    <h4>Card title</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Reprehenderit odit, dolorum quaerat quo aliquid modi
                      veritatis voluptatibus fugiat vero dolor illo eum
                      provident nisi quia totam. Numquam exercitationem et
                      ullam.
                    </p>
                  </div> -->
                  <img
                    src="@/assets/1+1/1+1ic-gorseller-2.png"
                    alt="1+1 image"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card">
                  <!-- <div class="card-header">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="card-body">
                    <h4>Card title</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Reprehenderit odit, dolorum quaerat quo aliquid modi
                      veritatis voluptatibus fugiat vero dolor illo eum
                      provident nisi quia totam. Numquam exercitationem et
                      ullam.
                    </p>
                  </div>-->
                  <img
                    src="@/assets/1+1/1+1ic-gorseller-3.png"
                    alt="1+1 image"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </b-tab>

          <!-- 2+1 tab -->
          <b-tab title="2+1">
            <!-- slider -->
            <VueperSlides
              fractions
              progress
              :bullets="false"
              :gap="5"
              autoplay
              :slide-ratio="1 / 4"
              class="no-shadow vueper-slide"
              :visible-slides="3"
              :dragging-distance="70"
              :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
            >
              <VueperSlide
                class="slide-item"
                v-for="(slide, i) in slides2_1"
                :key="i"
                :image="slide.image"
              >
              </VueperSlide>
            </VueperSlides>

            <!-- description -->
            <div class="row mt-5">
              <div class="col-lg-6 col-md-12">
                <img
                  class="img-thumbnail tab-desc-img"
                  src="@/assets/2+1/kunduCanyon-bedroom1.jpg"
                  alt="kundu kanyon"
                  loading="lazy"
                />
              </div>
              <div class="col desc-tab">
                <h1>{{ $t("FlatFeatures") }}</h1>
                <p>{{ $t("2+1FlatFeature1") }}</p>
                <p>{{ $t("2+1FlatFeature2") }}</p>
                <div>
                  <router-link to="/project">{{ $t("AllFlats") }}</router-link>
                </div>
              </div>
            </div>

            <!-- card -->
            <div class="row mt-5 card-section">
              <div class="col-lg-4">
                <div class="card">
                  <!-- <div class="card-header">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="card-body">
                    <h4>Card Title</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Reprehenderit odit, dolorum quaerat quo aliquid modi
                      veritatis voluptatibus fugiat vero dolor illo eum
                      provident nisi quia totam. Numquam exercitationem et
                      ullam.
                    </p>
                  </div> -->
                  <img
                    src="@/assets/2+1/2+1ic-gorseller.jpg"
                    alt="2+1 image"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card">
                  <!-- <div class="card-header">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="card-body">
                    <h4>Card title</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Reprehenderit odit, dolorum quaerat quo aliquid modi
                      veritatis voluptatibus fugiat vero dolor illo eum
                      provident nisi quia totam. Numquam exercitationem et
                      ullam.
                    </p>
                  </div> -->
                  <img
                    src="@/assets/2+1/2+1ic-gorseller-2.jpg"
                    alt="2+1 image"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card">
                  <!-- <div class="card-header">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="card-body">
                    <h4>Card title</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Reprehenderit odit, dolorum quaerat quo aliquid modi
                      veritatis voluptatibus fugiat vero dolor illo eum
                      provident nisi quia totam. Numquam exercitationem et
                      ullam.
                    </p>
                  </div> -->
                  <img
                    src="@/assets/2+1/2+1ic-gorseller-3.jpeg"
                    alt="2+1 image"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </b-tab>

          <!-- 3+1 tab -->
          <b-tab title="3+1">
            <!-- slider -->
            <VueperSlides
              fractions
              progress
              :bullets="false"
              autoplay
              :gap="5"
              :slide-ratio="1 / 4"
              class="no-shadow vueper-slide"
              :visible-slides="3"
              :dragging-distance="70"
              :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
            >
              <VueperSlide
                class="slide-item"
                v-for="(slide, i) in slides3_1"
                :key="i"
                :image="slide.image"
              >
              </VueperSlide>
            </VueperSlides>

            <!-- description -->
            <div class="row mt-5">
              <div class="col-lg-6 col-md-12">
                <img
                  class="img-thumbnail tab-desc-img"
                  src="@/assets/3+1/kunduCanyon-bedroomustkat1.jpg"
                  alt="kundu kanyon"
                  loading="lazy"
                />
              </div>
              <div class="col desc-tab">
                <h1>{{ $t("FlatFeatures") }}</h1>
                <p>{{ $t("3+1FlatFeature1") }}</p>
                <p>{{ $t("3+1FlatFeature2") }}</p>
                <div>
                  <router-link to="/project">{{ $t("AllFlats") }}</router-link>
                </div>
              </div>
            </div>

            <!-- card -->
            <div class="row mt-5 card-section">
              <div class="col-lg-4">
                <div class="card">
                  <!-- <div class="card-header">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="card-body">
                    <h4>Card Title</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Reprehenderit odit, dolorum quaerat quo aliquid modi
                      veritatis voluptatibus fugiat vero dolor illo eum
                      provident nisi quia totam. Numquam exercitationem et
                      ullam.
                    </p>
                  </div> -->
                  <img
                    src="@/assets/3+1/3+1ic-gorseller.jpg"
                    alt="3+1 image"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card">
                  <!-- <div class="card-header">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="card-body">
                    <h4>Card title</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Reprehenderit odit, dolorum quaerat quo aliquid modi
                      veritatis voluptatibus fugiat vero dolor illo eum
                      provident nisi quia totam. Numquam exercitationem et
                      ullam.
                    </p>
                  </div> -->
                  <img
                    src="@/assets/3+1/3+1ic-gorseller-2.jpg"
                    alt="3+1 image"
                    loading="lazy"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card">
                  <!-- <div class="card-header">
                    <i class="fa fa-info"></i>
                  </div>
                  <div class="card-body">
                    <h4>Card title</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Reprehenderit odit, dolorum quaerat quo aliquid modi
                      veritatis voluptatibus fugiat vero dolor illo eum
                      provident nisi quia totam. Numquam exercitationem et
                      ullam.
                    </p>
                  </div> -->
                  <img
                    src="@/assets/3+1/3+1ic-gorseller-3.png"
                    alt="3+1 image"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </b-tab>

          <!-- outside gallery -->
          <b-tab :title="$t('Outdoor')">
            <!-- slider -->
            <VueperSlides
              :bullets="true"
              autoplay
              :gap="4"
              :slide-ratio="1.5 / 4"
              class="no-shadow vueper-slide"
              :visible-slides="2"
              :dragging-distance="70"
              :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }"
            >
              <VueperSlide
                class="slide-item"
                v-for="(slide, i) in outside_gallery"
                :key="i"
                :image="slide.image"
              >
              </VueperSlide>
            </VueperSlides>
          </b-tab>
        </b-tabs>
      </div>
    </section>
    <!-- tabs end -->

    <!-- Location start -->
    <section class="location my-5">
      <div class="container">
        <div class="col text-center">
          <div class="my-4">
            <h2>
              {{ $t("ParcelInfoTitle") }}
            </h2>
            <p>{{ $t("ParcelInfos") }}</p>
            <span></span>
          </div>

          <div class="row pt-5">
            <div class="col-lg-6 col-md-12">
              <a
                href="images/kundu-canyon-location.jpg"
                target="_blank"
                title="kundu canyon location"
                loading="lazy"
              >
                <img
                  class="img-fluid"
                  src="@/assets/kundu-canyon-location.jpg"
                  alt="kundu canyon location"
                  loading="lazy"
                />
              </a>
            </div>
            <div class="col-lg-6 col-md-12">
              <a
                href="images/kundu-canyon-map.png"
                target="_blank"
                title="kundu canyon map"
                loading="lazy"
              >
                <img
                  class="img-fluid"
                  src="@/assets/kundu-canyon-map.png"
                  alt="kundu canyon map"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Location end -->
  </main>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Suites from "../components/Antalya-Suites-Comp.vue";
export default {
  components: {
    VueperSlides,
    VueperSlide,
    Suites,
  },
  data: () => ({
    slides1_1: [
      {
        // title: "Slide #1",
        // content: "Slide 1 content.",
        image: require("@/assets/1+1/kunduCanyon-american-kitchen.jpg"),
      },
      {
        image: require("@/assets/1+1/kunduCanyon-bedroom.jpg"),
      },
      {
        image: require("@/assets/1+1/kunduCanyon-bedroom2.jpg"),
      },
      {
        image: require("@/assets/1+1/kunduCanyon-bathroom.jpg"),
      },
      {
        image: require("@/assets/1+1/kunduCanyon-bathroom2.jpg"),
      },
      {
        image: require("@/assets/1+1/kunduCanyon-Salon.jpg"),
      },
    ],

    slides2_1: [
      {
        // title: "Slide #1",
        // content: "Slide 1 content.",
        image: require("@/assets/2+1/kunduCanyon-americankitchen3.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-americankithcen1.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-bathroom1.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-bathroom2.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-bedroom1.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-bedroom2.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-americankithcen2.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-bedroomustkat1.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-bedroomustkat2.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-livingroom1.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-livingroom2.jpg"),
      },
      {
        image: require("@/assets/2+1/kunduCanyon-upstairsbathroom.jpg"),
      },
    ],

    slides3_1: [
      {
        // title: "Slide #1",
        // content: "Slide 1 content.",
        image: require("@/assets/3+1/kunduCanyon-americankitchen3.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-americankithcen1.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-bathroom1.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-bathroom2.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-bathroom3.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-bedroom1.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-bedroom2.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-bedroomustkat1.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-bedroomustkat2.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-dressingroom.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-livingroom1.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-livingroom2.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-parentsbathroom.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-parentsbedroomupstairs.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-upstairsbathroom.jpg"),
      },
      {
        image: require("@/assets/3+1/kunduCanyon-upstairsbedroom1.jpg"),
      },
    ],

    // outside gallery
    outside_gallery: [
      {
        image: require("@/assets/outside gallery/kundu-kanyon-gallery-item-1.jpeg"),
      },
      {
        image: require("@/assets/outside gallery/kundu-kanyon-gallery-item-2.jpeg"),
      },
      {
        image: require("@/assets/outside gallery/kundu-kanyon-gallery-item-3.jpeg"),
      },
      {
        image: require("@/assets/outside gallery/kundu-kanyon-gallery-item-4.jpeg"),
      },
      {
        image: require("@/assets/outside gallery/kundu-kanyon-gallery-item-5.jpeg"),
      },
      {
        image: require("@/assets/outside gallery/kundu-kanyon-gallery-item-6.jpeg"),
      },
    ],
  }),
};
</script>