<template>
  <section>
    <section class="sub-page-wrapper"></section>
    <section class="project container">
      <div class="my-5 py-5">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <img
              class="img-thumbnail"
              src="@/assets/gallery/kundu-canyon-gallery-item.jpg"
              alt="kundu canyon gallery item"
              loading="lazy"
            />
          </div>
          <div class="col-lg-6 col-md-12 about-project">
            <div>
              <h1 class="">{{ $t("AboutTheProject") }}</h1>
              <p>
                {{ $t("AboutTheProjectDesc") }}
              </p>
            </div>
            <div>
              <ul>
                <li>{{ $t("AboutTheProjectFeature1") }}</li>
                <li>{{ $t("AboutTheProjectFeature2") }}</li>
                <li>{{ $t("AboutTheProjectFeature3") }}</li>
                <li>{{ $t("AboutTheProjectFeature4") }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="my-5">
        <div class="row">
          <div class="col-lg-6 col-md-12 about-project">
            <h1 class="">{{ $t("ProjectOpportunities") }}</h1>
            <ul class="">
              <li>{{ $t("ProjectOpportunities1") }}</li>
              <li>{{ $t("ProjectOpportunities2") }}</li>
              <li>{{ $t("ProjectOpportunities3") }}</li>
              <li>{{ $t("ProjectOpportunities4") }}</li>
              <li>{{ $t("ProjectOpportunities5") }}</li>
              <li>{{ $t("ProjectOpportunities6") }}</li>
              <li>{{ $t("ProjectOpportunities7") }}</li>
              <li>{{ $t("ProjectOpportunities8") }}</li>
              <li>{{ $t("ProjectOpportunities9") }}</li>
              <li>{{ $t("ProjectOpportunities10") }}</li>
            </ul>
          </div>
          <div class="col-lg-6 col-md-12">
            <img
              class="img-thumbnail"
              src="@/assets/gallery/kundu-canyon-gallery-item-2.jpg"
              alt="kundu canyon gallery item"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: "Project-Page",
  created() {},
};
</script>

<style>
</style>