
<template>
  <header>
    <!-- Navbar Start -->
    <nav
      v-if="show_web_header == true"
      class="navbar navbar-expand-lg py-0 px-5"
    >
      <router-link
        to="/"
        class="navbar-brand d-flex align-items-center px-0 border-bottom"
        title="logo"
      >
        <!-- <img class="logo" src="" alt="kundu canyon logo" /> -->
      </router-link>
      <div class="collapse navbar-collapse border-bottom" id="navbarCollapse">
        <div class="navbar-nav ms-auto p-4 p-lg-0">
          <div class="nav-item dropdown">
            <div
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              title="Dil Değiştirin"
            >
              <img alt="Language" src="../assets/world.svg" />
            </div>
            <div class="dropdown-menu bg-light m-0">
              <span
                to="/"
                class="dropdown-item"
                title="türkçe"
                @click="changeLanguage('tr')"
                >Türkçe</span
              >
              <span
                class="dropdown-item"
                title="english"
                @click="changeLanguage('en')"
                >English</span
              >
            </div>
          </div>
          <router-link
            to="/"
            class="nav-item nav-link active"
            :title="$t('Home')"
            >{{ $t("Home") }}</router-link
          >
          <router-link
            to="/project"
            class="nav-item nav-link"
            :title="$t('Project')"
            >{{ $t("Project") }}</router-link
          >
          <router-link
            to="/contact"
            class="nav-item nav-link"
            :title="$t('Contact')"
            >{{ $t("Contact") }}</router-link
          >
          <!-- <router-link to="/" class="nav-item nav-link" :title="$t('Blog')">{{
            $t("Blog")
          }}</router-link> -->

          <!--  <div class="nav-item dropdown">
            <div
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              :title="$t('Pages')"
            >
              {{ $t("Pages") }}
            </div>
             <div class="dropdown-menu bg-light m-0">
              <router-link to="/turizm" class="dropdown-item" title="turizm"
                >Turizm</router-link
              >
              <router-link
                to="/teknoloji"
                class="dropdown-item"
                title="teknoloji"
                >Teknoloji</router-link
              >
            </div> 
          </div>-->
        </div>
      </div>
    </nav>

    <!-- mobile navbar section -->
    <nav v-if="show_mobile_header == true" id="mobile-navbar">
      <div class="container-fluid h-100">
        <div
          class="row h-100 text-center d-flex justify-content-center align-items-center"
        >
          <div class="col d-flex justify-content-center align-items-center">
            <div>
              <ul>
                <li>
                  <b-dropdown :text="this.$i18n.locale" left>
                    <b-dropdown-item
                      ><span
                        class="dropdown-item"
                        title="türkçe"
                        @click="changeLanguage('tr')"
                        >Türkçe</span
                      ></b-dropdown-item
                    >
                    <b-dropdown-item
                      ><span
                        class="dropdown-item"
                        title="english"
                        @click="changeLanguage('en')"
                        >English</span
                      ></b-dropdown-item
                    >
                  </b-dropdown>
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <router-link to="/" :title="$t('Home')">
              <img
                class="mobile-logo"
                src="../assets/kundu-kanyon-logo.png"
                alt="kundu kanyon logo"
              />
            </router-link>
          </div>

          <div class="col">
            <div>
              <b-card class="border-0">
                <ul class="col mobile-nav-items">
                  <li>
                    <b-dropdown text="⇆" right>
                      <b-dropdown-item
                        ><router-link
                          to="/"
                          class="nav-item nav-link active text-center"
                          :title="$t('Home')"
                          >{{ $t("Home") }}</router-link
                        >
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <router-link
                          to="/project"
                          class="nav-item nav-link text-center"
                          :title="$t('Project')"
                          >{{ $t("Project") }}</router-link
                        ></b-dropdown-item
                      >
                      <b-dropdown-item>
                        <router-link
                          to="/contact"
                          class="nav-item nav-link text-center"
                          :title="$t('Contact')"
                          >{{ $t("Contact") }}</router-link
                        ></b-dropdown-item
                      >
                    </b-dropdown>
                  </li>
                </ul>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Navbar End -->
  </header>
</template>
<script>
// require("../../public/js/app")
export default {
  data() {
    return {
      show_web_header: false,
      show_mobile_header: false,
    };
  },
  created() {
    // eğer ekran genişliği değiştirilirse bu event sayesinde fonksiyonu yeniden tetikliyoruz
    window.addEventListener("resize", () => {
      this.FindScreenSize();
    });
    //ilk açılışta her zaman bu kontrol gerçekleşecek
    this.FindScreenSize();
  },
  methods: {
    FindScreenSize() {
      this.show_mobile_header = window.innerWidth < 996; // window.innerWidth < 996 bu kod boolean döndürür.
      this.show_web_header = !this.show_mobile_header; // show_web_header değişkeni sürekli diğerine bağlı olarak zıt şekilde güncellenir.
    },
    changeLanguage(lang) {
      if (lang == "tr") {
        this.$i18n.locale = "tr";
        // Yeni dil değerini yerel depolamada saklayın
        localStorage.setItem("lang", this.$i18n.locale);
        return;
      }
      // Dil değiştirme işlemlerini burada yapabilirsiniz
      // Örneğin, aktif dil Türkçe ise İngilizce'ye, İngilizce ise Türkçe'ye geçebilirsiniz
      this.$i18n.locale = this.$i18n.locale = "en";
      // Yeni dil değerini yerel depolamada saklayın
      localStorage.setItem("lang", this.$i18n.locale);
    },

    // closeCollapse() {
    //   this.isCollapsed = true;
    // },
  },
};
</script>